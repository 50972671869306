<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />
    <Menubar :model="items">
      <template #start>
        <router-link :to="{ name: 'Home' }">
          <img
            alt="logo"
            src="./assets/images/wini.png"
            height="50"
            class="p-mr-2"
          />
        </router-link>
      </template>
    </Menubar>
    <router-view class="view" />
    <div class="bg-black overflow-hidden py-5 text-center">
      <div
        class="flex-1 md:flex-none flex align-items-center justify-content-center row pb-40 footer"
      >
        <div class="col-sm-4 col-md-3 pb-20">
          <ul class="list-footer">
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Loan' }">
                Préstamos personales
              </router-link>
            </li>
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Healers' }">
                Reunificadores de deuda
              </router-link>
            </li>
            <li>
              &emsp;
            </li>
          </ul>
        </div>
        <div class="col-sm-4 col-md-3 pb-20">
          <ul class="list-footer">
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Terms' }">
                Términos y condiciones
              </router-link>
            </li>
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Privacy' }">
               Política de privacidad
              </router-link>
            </li>
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Faq' }">
                Preguntas frecuentes
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-4 col-md-3 col-md-offset-1">
          <ul class="list-footer">
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Brand' }">
                Quiero anunciar mi marca
              </router-link>
            </li>
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Contact' }">
                Contacto
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-2 mb-4">
          <p class="p p-fb">Síguenos en:</p>
          <a :href="fbPage" target="_blank">
            <Button
              icon="pi-facebook"
              iconPos="right"
              class="p-button-raised p-button-rounded"
              ><i class="fab fa-facebook-f"></i
            ></Button>
          </a>
        </div>
      </div>
      <div class="col-sm-12">
        <p class="p">
          Nuestra oficina registrada: Av. Paseo de las Palmas 735, Lomas de
          Chapultepec I Secc, Miguel Hidalgo, 11000 Ciudad de México, CDMX.
        </p>

        <p class="p">
          {{ year }} © {{ name }} - Todos los derechos reservados. Al usar este
          sitio, aceptas nuestra política de privacidad, así como nuestros
          términos y condiciones.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Button: Button,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
      items: [
        {
          label: "Inicio",
          to: "/#home",
        },
        {
          label: "Cómo funciona",
          to: "/#work",
        },
        {
          label: "Beneficios",
          to: "/#benefits",
        },
        {
          label: "Quiénes somos",
          to: "/quienes-somos",
        },
        {
          label: "Contacto",
          to: "/contacto",
        },
      ],
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
};
</script>

<style>
.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: #fbc60e;
  font-size: 12px;
}

a {
  color: #fbc60e !important;
}

.p {
  color: white;
  font-size: 14px;
  margin: 1em;
}

.bg-black {
  background-color: #000;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}

.p-menubar {
  position: fixed !important;
  z-index: 100;
  width: 100%;
  top: 0;
}

.p-menubar .p-menubar-button {
  position: absolute;
  right: 5%;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
}

.p-menubar .p-menubar-root-list {
  position: absolute;
  right: 0px;
}

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}
</style>
