
  import { defineComponent } from "vue";
  import Card from "primevue/card";
  import Button from "primevue/button";
  import * as FacebookServices from "../api/helpers/Facebook";
  export default defineComponent({
    name: "Home",
    components: {
      Card,
      Button,
    },
    mounted() {
      FacebookServices.pageView();
      sessionStorage.clear();
      localStorage.clear();
      const urlParams = new URLSearchParams(window.location.search);
        sessionStorage.setItem("SID", urlParams.get("SID") || '');
    },
    methods: {
      go() {
        this.$router.push("/cuestionario");
      },
    },
  });
