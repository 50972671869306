import axios, { AxiosInstance } from "axios";

export class Gondor {
  static http: AxiosInstance;
  static baseURL = process.env.VUE_APP_BACK;

  constructor() {
    Gondor.http = axios.create({
      baseURL: Gondor.baseURL,
      headers: {
        client_id: process.env.VUE_APP_CLIENT_ID,
      },
    });
  }
}

const gondor = new Gondor();

export default gondor;
