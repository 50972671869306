import { Gondor } from "../Gondor";
import axios from "axios";

const ACTIONS = {
  FACEBOOK: "/fb/conversion",
};

export function pageView() {
  axios.get("https://api.ipify.org?format=json").then((res) => {
      const userAgent = navigator.userAgent;
      const ipAddress = res.data.ip;
      const origin = process.env.VUE_APP_BASE_URL;
      if(!sessionStorage.getItem("eventID")){
        sessionStorage.setItem("eventID", `${origin}/${ipAddress}/${String(new Date().getDate())}/${String(new Date().getMonth())}/${String(new Date().getFullYear())}`)
      }
      window.dataLayer?.push({
        'eventID':  sessionStorage.getItem("eventID")
      });

    return Gondor.http
        .post(ACTIONS.FACEBOOK, {
          pixelId:process.env.VUE_APP_PIXEL_ID,
          token: process.env.VUE_APP_CAPI_TOKEN,
          ipAddress: ipAddress,
          userAgent: userAgent,
          eventName: "PageView",
          eventID : sessionStorage.getItem("eventID"),
          origin: origin,
        }).then((res) => {
          console.log(res);
         });
    });
}
export function lead() {
  axios.get("https://api.ipify.org?format=json").then((res) => {
      const leadId = sessionStorage.getItem("leadId") || "";
      const userAgent = navigator.userAgent;
      const ipAddress = res.data.ip;
      const origin = process.env.VUE_APP_BASE_URL;

         return Gondor.http.post(ACTIONS.FACEBOOK, {
              pixelId:process.env.VUE_APP_PIXEL_ID,
              token: process.env.VUE_APP_CAPI_TOKEN,
              leadid: leadId,
              ipAddress: ipAddress,
              userAgent: userAgent,
              eventName: "Lead",
              eventID : sessionStorage.getItem("eventID"),
              origin: origin,
            });
    });
    
}
